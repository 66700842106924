<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="ফর্ম – ১২ : বিস্তারিত বাজেট"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-6">
          <label for="colFormLabel" >Select FD6</label>
          <v-select
            placeholder="Select FD6"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
            @option:selected="onSelectProject"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-6">
          <label for="colFormLabel">FD6 Budget</label>
          <v-select
            placeholder="Select FD6 Budget"
            v-model="budgetMasterId"
            :options="donors"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="clearfix"></div>
        <div class="col-2 col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
          <button
              style="min-width: 64px;"
              @click="exportReport()"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          >Export
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import Loader from '@/components/atom/LoaderComponent'
import {inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import handleInventory from "@/services/modules/inventory";
import handleFD6s from "@/services/modules/procurement/fd6";

const {fetchFD6s, fetchFD6Donors} = handleFD6s();
const {fetchHome} = handleInventory();

const $route = useRoute();
const showError = inject('showError');
const query = `?company_id=${$route.params.companyId}`;
const token = localStorage.getItem('token');
const projects = ref([]);
const donors = ref([]);
const projectId = ref(null);
const budgetMasterId = ref(null);
const isLoading = ref(false);
const donorPVAReport = ref([]);
const budgetBreakDown = ref({});
const budgetHistory = ref({});
const actual = ref({});


// Methods
const onSelectProject = () => {
  isLoading.value = true;
  donorPVAReport.value = [];
  budgetHistory.value = {};
  budgetBreakDown.value = {};
  fetchFD6Donors(projectId.value, query)
    .then( res => {
      if(res.status) {
        budgetMasterId.value = null;
        donors.value = res.data;
      } else {
        showError(res.message)
      }
    })
    .catch( err => {
      showError(err.response.message);
    })
    .finally( () => isLoading.value = false)
}

const exportReport = () => {
  if (projectId.value === null) {
    showError('Please select a fd6');
    return;
  }
  let objQuery = {
    company_id: $route.params.companyId,
    budget_master_id: budgetMasterId.value ?? '',
    _token: token,
    report_type: "export-fd6",
    report_title: "ফর্ম – ১২ : বিস্তারিত বাজেট"
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/ngoab-detailed-budget-report/${projectId.value}?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted( () => {
  isLoading.value = true;
  Promise.all([
    fetchFD6s(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      } else {
        showError(res.message)
      }
    }),
  ])
    .then ( () => isLoading.value = false)
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})

</script>
